import { NgModule } from '@angular/core';
// import { Configuration } from 'msal';
// import {
//   MsalModule,
//   MsalService,
//   MSAL_CONFIG,
//   MSAL_CONFIG_ANGULAR,
//   MsalAngularConfiguration,
// } from '@azure/msal-angular';
// import { environment } from 'libs/client/environments/environment';
// import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
// import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular'; // Redirect component imported from msal-angular

// function MSALConfigFactory(): Configuration {
//   return {
//     auth: {
//       clientId: '47d83043-b993-476d-aa2c-f61f602e94f4',
//       authority: 'https://login.microsoftonline.com/common/',
//       validateAuthority: true,
//       redirectUri: 'http://localhost:4200/',
//       postLogoutRedirectUri: 'http://localhost:4200/',
//       navigateToLoginRequestUrl: true,
//     },
//     cache: {
//       cacheLocation: 'localStorage',
//       storeAuthStateInCookie: false, // set to true for IE 11
//     },
//   };
// }

// export const protectedResourceMap: [string, string[]][] = [
//   ['https://graph.microsoft.com/v1.0/me', ['user.read']],
// ];

// function MSALAngularConfigFactory(): MsalAngularConfiguration {
//   return {
//     popUp: false,
//     consentScopes: ['user.read', 'email', 'profile', 'offline_access'],
//     protectedResourceMap,
//     extraQueryParameters: {},
//   };
// }

@NgModule({
  //   imports: [
  //     MsalModule.forRoot(
  //       {
  //         auth: {
  //           clientId: environment.MSOFT_AD_CLIENT_ID,
  //           authority: 'https://login.microsoftonline.com/common/' + environment.MS_TENANT_ID,
  //           validateAuthority: true,
  //           redirectUri: environment.REDIRECT_URI, // update based on env
  //           postLogoutRedirectUri: environment.REDIRECT_URI, // update based on env
  //           navigateToLoginRequestUrl: true,
  //         },
  //         cache: {
  //           cacheLocation: 'localStorage',
  //           storeAuthStateInCookie: false, // set to true for IE 11
  //         },
  //         framework: {
  //           protectedResourceMap: new Map(protectedResourceMap),
  //         },
  //         // system: {
  //         //   logger: new Logger(loggerCallback, options),
  //         // },
  //       },
  //       {
  //         popUp: false,
  //         consentScopes: ['user.read', 'email', 'profile', 'offline_access'],
  //         extraQueryParameters: {},
  //       }
  //     ),
  //   ],
  //   providers: [
  //     {
  //       provide: MSAL_CONFIG,
  //       useFactory: MSALConfigFactory,
  //     },
  //     {
  //       provide: MSAL_CONFIG_ANGULAR,
  //       useFactory: MSALAngularConfigFactory,
  //     },
  //     MsalService,
  //   ],
})
export class MicrosoftAuthModule {}
