import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectSearchInputComponent } from './project-search-input/project-search-input.component';
import { ClientUiLoadingButtonModule } from '@prime/client/ui/loading-button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectSearchListComponent } from './project-search-list/project-search-list.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ClientUiLoadingSpinnerModule } from '@prime/client/ui/loading-spinner';
import { ProjectSelectDialogComponent } from './project-select-dialog/project-select-dialog.component';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    ClientUiLoadingButtonModule,
    ReactiveFormsModule,
    MatDialogModule,
    ClientUiLoadingSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    MatTableModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
  ],
  declarations: [
    ProjectSearchInputComponent,
    ProjectSearchListComponent,
    ProjectSelectDialogComponent,
  ],
  exports: [ProjectSearchInputComponent],
})
export class ClientUiProjectSearchModule {}
