<mat-toolbar>
  <span class="spacer">
    <img src="/assets/PRIMe_Orange_White.png" height="35" alt="PRIMe" />
  </span>
</mat-toolbar>

<div class="selection-container">
  <div class="bordered input-filters-box">
    <form novalidate [formGroup]="searchGroup">
      <div class="row">
        <div class="col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>Customer</mat-label>
            <input type="text" aria-label="Customer" matInput formControlName="customer" [matAutocomplete]="autoCust" />
            <mat-autocomplete #autoCust="matAutocomplete" [displayWith]="displayCustomer">
              <mat-option *ngFor="let cust of filteredCustomersOptions | async" [value]="cust.customer_id">
                {{ cust.customer }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="col-md-3">
          <mat-form-field appearance="outline">
            <mat-label>Salesman</mat-label>
            <input type="text" aria-label="Salesman" matInput formControlName="salesman"
              [matAutocomplete]="autoSales" />
            <mat-autocomplete #autoSales="matAutocomplete" [displayWith]="displaySalesman">
              <mat-option *ngFor="let sales of filteredSalesmanOptions | async" [value]="sales.autoID">
                {{ sales.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="col-md-3">
          <mat-form-field appearance="outline">
            <mat-label>Project Coordinator</mat-label>
            <mat-select formControlName="coordinator">
              <mat-option *ngFor="let user of selectOptions?.users" [value]="user.user_id">{{ user.full_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select formControlName="project_status">
              <mat-option *ngFor="let status of selectOptions?.projectStatuses" [value]="status.status_name">
                {{ status.status_name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-3">
          <mat-form-field appearance="outline">
            <mat-label>Region</mat-label>
            <mat-select formControlName="region">
              <mat-option *ngFor="let region of selectOptions?.regions" [value]="region.region_id">{{ region.region }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Project Name or Number</mat-label>
            <input type="text" matInput placeholder="Search Project Name or Number"
              formControlName="project_name_or_number" cdkFocusInitial />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <mat-form-field appearance="outline">
            <mat-label>VDC Project Type</mat-label>
            <mat-select formControlName="project_type">
              <mat-option *ngFor="let type of selectOptions?.projectTypes" [value]="type.projtypeid">{{ type.projtype }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-3">
          <mat-form-field appearance="outline">
            <mat-label>Market</mat-label>
            <mat-select formControlName="market">
              <mat-option *ngFor="let market of selectOptions?.markets" [value]="market.market_id">{{ market.market }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-3">
          <mat-form-field appearance="outline">
            <mat-label>Project Type</mat-label>
            <mat-select formControlName="additional_project_type">
              <mat-option *ngFor="let addProjType of selectOptions?.additionalProjectTypes"
                [value]="addProjType.project_type_id">{{ addProjType.project_type }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-3">
          <mat-form-field appearance="outline">
            <mat-label>Service</mat-label>
            <mat-select formControlName="service">
              <mat-option *ngFor="let service of selectOptions?.services" [value]="service.service_name">{{
                service.service_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <button mat-raised-button class="pull-right" color="accent" (click)="getProjects()">
            Submit
          </button>
          <button mat-raised-button class="pull-right" color="warn" style="margin-right: 10px;" (click)="clearFields()">
            Clear
          </button>
          <button mat-raised-button [mat-dialog-close] class="dark-shadow pull-right" style="margin-right: 10px;">
            Close
          </button>
        </div>
      </div>
    </form>
  </div>

  <div>
    <prime-project-search-list [resultSet]="resultSet" (projectSelected)="navToProject($event)">
    </prime-project-search-list>
    <div class="spinner" *ngIf="fetching">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>