import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingButtonComponent } from './loading-button/loading-button.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, MatButtonModule],
  declarations: [LoadingButtonComponent],
  exports: [LoadingButtonComponent],
})
export class ClientUiLoadingButtonModule {}
