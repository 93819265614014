export const environment = {
  production: false,
  AZURE: false,
  QA: true,
  GRAPHQL_URI: 'https://prime-lite-qa-api-uat.azurewebsites.net/graphql',
  ENDPOINT_BASE: 'https://prime-lite-qa-api-uat.azurewebsites.net',
  WS_ENDPOINT: '',
  API_BASE_URL: '/vdc/api/v1',
  REDIRECT_URI: 'https://prime-lite-qa-api-uat.azurewebsites.net',
  MSOFT_AD_CLIENT_ID: '47d83043-b993-476d-aa2c-f61f602e94f4',
  MS_TENANT_ID: 'd2bfb7fe-352c-4886-98a3-044da283af7e',
  PHP_ENDPOINT_BASE: 'https://prime-lite-reporting-uat.azurewebsites.net',
  MAPBOX_TOKEN: 'pk.eyJ1IjoibnZpdHVsbGkiLCJhIjoiY2tyNmVkZ2tyMHBrcjJxcWwzNWc1enR0MCJ9.jYj30kp88nHZgA1giffo2g',
};