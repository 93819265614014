import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClientEnvironmentService } from '@prime/client/env';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApiKeysService {
    private _clientApiKeys = new BehaviorSubject<{ googleApiKey: string }>(null);
    get clientApiKeys() {
        return this._clientApiKeys.asObservable();
    }
    constructor(
        private http: HttpClient,
        private clientEnv: ClientEnvironmentService,
    ) { }

    async init() {
        const googleApiKey = await this.getGoogleApiKey();
        this._clientApiKeys.next({ googleApiKey })
    }

    async getGoogleApiKey(): Promise<string> {
        let key: string = '';
        await this.http.get(`${this.clientEnv.env.ENDPOINT_BASE}${this.clientEnv.env.API_BASE_URL}/app/google-api-key`)
            .toPromise()
            .then((result: { data: string }) => {
                key = result.data;
            });
        return key;
    }

    async getCkeditorApiKey(): Promise<string> {
        let key: string = '';
        await this.http.get(`${this.clientEnv.env.ENDPOINT_BASE}${this.clientEnv.env.API_BASE_URL}/app/ckeditor-api-key`)
            .toPromise()
            .then((result: { data: string }) => {
                key = result.data;
            });
        return key;
    }
}
