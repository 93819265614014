import { Injectable } from '@angular/core';
import {
  AddNewProjectInput,
  ProjectStatusUpdateInput,
  UpdateProjectInfoViewInput,
  UpdateProjectLocationInput,
  UpdateProjectStatusDateInput,
  UpdateProjectTeamMemberInput,
} from '@prime/types';
import {
  AddNewProjectGQL,
  AddProjectTeamMemberGQL,
  GetProjectEstimatingDataDocument,
  GetProjectEstimatingDataQuery,
  GetProjectInformationViewDocument,
  GetProjectInformationViewQuery,
  RemoveProjectTeamMemberGQL,
  UpdateProjectInfoViewGQL,
  UpdateProjectLocationGQL,
  UpdateProjectStatusDateGQL,
  UpdateProjectStatusGQL,
} from '@prime/client/services';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProjectBaseMutationService {
  constructor(
    private newProjectGQL: AddNewProjectGQL,
    private generalInfoService: UpdateProjectInfoViewGQL,
    private addMemberGQL: AddProjectTeamMemberGQL,
    private removeMember: RemoveProjectTeamMemberGQL,
    private projLocation: UpdateProjectLocationGQL,
    private statusGql: UpdateProjectStatusGQL,
    private updateStatusDate: UpdateProjectStatusDateGQL
  ) { }

  createNewProject(input: AddNewProjectInput) {
    return this.newProjectGQL.mutate({ input }).pipe(map((result) => result?.data?.addNewProject));
  }

  updateProjectGeneralInfo(input: UpdateProjectInfoViewInput) {
    return this.generalInfoService
      .mutate({
        input,
      },
        {
          update: (proxy, { data: { updateProjectInfoView } }) => {
            try {
              const data = proxy.readQuery<GetProjectEstimatingDataQuery>({
                query: GetProjectEstimatingDataDocument,
                variables: { proj_id: input.proj_id },
              });
              data.project.offerings = updateProjectInfoView.offerings;
              proxy.writeQuery({ query: GetProjectEstimatingDataDocument, data });
            } catch (error) {
              // Do nothing
            }
          },
        }
      )
      .pipe(map((result) => result.data.updateProjectInfoView))
      .toPromise();
  }

  updateProjectStatus(input: ProjectStatusUpdateInput) {
    return this.statusGql.mutate({ input }).pipe(map((result) => result.data.updateProjectStatus));
  }

  updateProjectStatusDate(input: UpdateProjectStatusDateInput) {
    return this.updateStatusDate
      .mutate({ input })
      .pipe(map((result) => result.data.updateProjectStatusDate));
  }

  addProjectTeamMember(input: UpdateProjectTeamMemberInput) {
    return this.addMemberGQL
      .mutate(
        { input },
        {
          update: (proxy, { data: { addProjectTeamMember } }) => {
            const data = proxy.readQuery<GetProjectInformationViewQuery>({
              query: GetProjectInformationViewDocument,
              variables: { proj_id: input.proj_id },
            });
            data.project.team_members = addProjectTeamMember;
            proxy.writeQuery({ query: GetProjectInformationViewDocument, data });
          },
        }
      )
      .toPromise();
  }

  removeProjectTeamMember(input: UpdateProjectTeamMemberInput) {
    return this.removeMember
      .mutate(
        { input },
        {
          update: (proxy, { data: { removeProjectTeamMember } }) => {
            const data = proxy.readQuery<GetProjectInformationViewQuery>({
              query: GetProjectInformationViewDocument,
              variables: { proj_id: input.proj_id },
            });
            data.project.team_members = removeProjectTeamMember;
            proxy.writeQuery({ query: GetProjectInformationViewDocument, data });
          },
        }
      )
      .toPromise();
  }

  updateProjectLocation(input: UpdateProjectLocationInput) {
    return this.projLocation
      .mutate(
        { input },
        {
          update: (proxy, { data: { updateProjectLocation } }) => {
            const data = proxy.readQuery<GetProjectInformationViewQuery>({
              query: GetProjectInformationViewDocument,
              variables: { proj_id: input.proj_id },
            });
            data.project.project_location = updateProjectLocation;
            proxy.writeQuery({ query: GetProjectInformationViewDocument, data });
          },
        }
      )
      .toPromise();
  }
}
