import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MenuListModule } from '@prime/client/ui/menu-list';
import { PrimeContainerComponent } from './prime-container/prime-container.component';
import { AppRoutingModule } from './routes/app-routing.module';
import { ClientServicesVdcDialogsModule } from '@prime/client/services/dialogs';
import { PrimeUserRecentProjectsViewComponent } from './prime-user-recent-projects-view/prime-user-recent-projects-view.component';
import { UserRecentProjectsViewModule } from '@prime/client/ui/user-recent-projects-view';
import { ApolloGraphQLModule } from '@prime/client/graphql';
import { IonicModule } from '@ionic/angular';
import { NavOverlayComponent } from './nav-overlay/nav-overlay.component';
import { ClientUiLoadingSpinnerModule } from '@prime/client/ui/loading-spinner';
import { PrimeDateFormatProvider, PrimeDateProvider } from '@prime/client/providers';
import { ClientUiProjectSearchModule } from '@prime/client/ui/project-search';
import { NativeDateModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ClientUiUserMenuModule } from '@prime/client/ui/user-menu';
import {
  AuthInterceptor,
  ErrorInterceptor,
  LoadingInterceptor,
} from '@prime/client/services/interceptors';
import { DatePipe } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthService } from '@prime/client/services/client-auth';
import { ReactiveFormsModule } from '@angular/forms';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
];

export const appInitializer =
  { provide: APP_INITIALIZER, useFactory: login, deps: [AuthService], multi: true };

function login(auth: AuthService) {
  return async () => {
    await auth.init(true);
  }
}
@NgModule({
  declarations: [
    AppComponent,
    PrimeContainerComponent,
    PrimeUserRecentProjectsViewComponent,
    NavOverlayComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MenuListModule,
    MatSidenavModule,
    LayoutModule,
    ClientServicesVdcDialogsModule,
    UserRecentProjectsViewModule,
    ClientUiUserMenuModule,
    MatToolbarModule,
    ApolloGraphQLModule,
    ClientUiProjectSearchModule,
    NativeDateModule,
    IonicModule.forRoot(),
    ClientUiLoadingSpinnerModule,
    MatSnackBarModule,
    ReactiveFormsModule
  ],
  providers: [PrimeDateProvider, PrimeDateFormatProvider, httpInterceptorProviders, DatePipe, appInitializer],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() { }
}
