import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'vdc-error-dialog',
  templateUrl: './vdc-error-dialog.component.html',
  styleUrls: ['./vdc-error-dialog.component.scss']
})
export class VdcErrorDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {}
}
