import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { VdcDialogService } from '@prime/client/services/dialogs';
import { of } from 'rxjs';
import { AuthService } from '@prime/client/services/client-auth';
import { User } from '@prime/types';

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private dialogs: VdcDialogService, private _authService: AuthService) {}

  ctr: number = 0
  logout = true;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(response => {
        if (response instanceof HttpErrorResponse && response.status === 401) {
          this.handleUnauthorizedError();
        } else if (response instanceof HttpErrorResponse) {
          console.error('ErrorInterceptor:intercept Server error: ',response);
          // // If performing action from an open dialog, we first need to close the dialog then display error messages
          // const openDialogs = this.dialogs.openDialogs;
          // if (openDialogs.length) {
          //   const dialogToClose = openDialogs[openDialogs.length - 1];
          //   dialogToClose?.close();
          // }
          // this.dialogs.showError(response.statusText);
          this.dialogs.staticSnackBarMessage(response.statusText, 'Close');
          // this.dialogs.snackBarMessage('Error', 'Close', 5000);
        }
        if(req && (req.url.includes('mulesoft') || req.url.includes('mendix'))) {
          return throwError(response);
        } else {
          return of(null);
        }
      })
    );
  }

  private handleUnauthorizedError() {
    this.ctr++;
    if(this.ctr == 1){
      this._authService.getRefreshToken().subscribe({
        next: (user: User) => {
          this._authService.setTokens(user);
          location.reload();
          this.logout = false;
          return;
        },
        complete: () =>{
          if(this.logout){
            this._authService.logOut();
            location.reload();  
        }
        }
      });
    }
  }
}
