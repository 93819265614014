import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRecentProjectsComponent } from './user-recent-projects/user-recent-projects.component';
import { ClientUiProjectCardModule } from '@prime/client/ui/project-card';
import { ClientUiLoadingSpinnerModule } from '@prime/client/ui/loading-spinner';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    ClientUiProjectCardModule,
    ClientUiLoadingSpinnerModule,
    MatIconModule
  ],
  declarations: [UserRecentProjectsComponent],
  exports: [UserRecentProjectsComponent],
})
export class UserRecentProjectsViewModule {}
