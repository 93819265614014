import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { ApiKeysService, OverlayMessageService } from '@prime/client/services/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClientEnvironmentService } from '@prime/client/env';

@Component({
  selector: 'prime-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  showOverlay: boolean;

  constructor(
    public overlayMsg: OverlayMessageService,
    private router: Router,
    private snackBar: MatSnackBar,
    private clientEnv: ClientEnvironmentService,
    private apiKeysService: ApiKeysService,
  ) {
    this.overlayMsg.overlay.subscribe((value) => {
      setTimeout(() => {
        this.showOverlay = value;
      });
    })
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        this.overlayMsg.setOverlay();
      } else if (event instanceof NavigationEnd) {
        this.overlayMsg.dismissOverlay();
      }
    });
  }

  async ngOnInit() {
    if (this.clientEnv.env.QA) {
      this.snackBar.open('Prime QA Version');
    }
    await this.apiKeysService.init();
  }
}
