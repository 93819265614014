import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientEnvironmentService } from '@prime/client/env';
import { UpdateRecentUserProjectsGQL } from '@prime/client/services';
import { AuthService } from '@prime/client/services/client-auth';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class VdcUsersClientMutationService {
  constructor(private auth: AuthService, private recentProjGQL: UpdateRecentUserProjectsGQL,
    private http: HttpClient, private clientEnv: ClientEnvironmentService) {}

  updateRecentUserProjects(proj_id: number) {
    return this.recentProjGQL
      .mutate({
        input: {
          user_id: this.auth.currentUser.user_id,
          proj_id: proj_id,
        },
      })
      .pipe(map((result) => result.data.updateRecentUserProjects))
      .toPromise();
  }

  updateUserFavorite(proj_id, isFavorite){
    return this.http.patch<any>(this.clientEnv.env.ENDPOINT_BASE + '/users/projects/updateFavorite', {
      "proj_id": proj_id,
      "user_id": this.auth.currentUser.user_id,
      "isFavorite": isFavorite
    });
  }
}
