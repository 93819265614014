import { Injectable } from '@angular/core';
import {
  GetCurrenciesByRegionGQL,
  GetFilteredPriceListsGQL,
  GetCommonSelectOptionsGQL,
} from '@prime/client/services';
import { Currency, PriceList } from '@prime/types';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { map } from 'rxjs/operators';
import { OptionsUiMap } from './options-map';
import { OptionsUiUtilService } from './options-ui-util.service';
import { GetTrackingActivityGroupsGQL } from '@prime/client/services';

@Injectable({ providedIn: 'root' })
export class ProjectSelectOptionsQueryService {
  constructor(
    private activityGroups: GetTrackingActivityGroupsGQL,
    private currencyGQL: GetCurrenciesByRegionGQL,
    private plFilter: GetFilteredPriceListsGQL,
    private selectOptionsGQL: GetCommonSelectOptionsGQL,
    private apollo: Apollo,
    private optionUtils: OptionsUiUtilService
  ) {}

  async getTrackingActivityGroupOptions() {
    return await this.activityGroups
      .fetch()
      .pipe(map((results) => results.data.projectSelectOptions.trackingActivityGroups))
      .toPromise();
  }

  async getCurrencyByRegion(region_id: number): Promise<Currency[]> {
    return this.currencyGQL
      .fetch({
        region_id,
      })
      .pipe(map((result) => result.data.currencyByRegion))
      .toPromise();
  }

  async getFilteredPriceList(region_id: number, currency: string): Promise<PriceList[]> {
    return this.plFilter
      .fetch({
        region_id,
        currency,
      })
      .pipe(map((result) => result.data.filteredPriceList))
      .toPromise();
  }

  async getCommonSelectOptions() {
    const options = await this.selectOptionsGQL
      .fetch()
      .pipe(map((results) => results.data.projectSelectOptions))
      .toPromise();
    this.optionUtils.addCurrentOptions(options);
    return options;
  }

  async fetchOption(optionName: string) {
    const opt = OptionsUiMap.find((opt) => opt.optionName === optionName);
    if (!opt || !opt.fragmentName || !opt.queryFragment) {
      console.error(`No opt.queryFragment or queryFragment for option ${optionName}`);
      return;
    }
    try {
      const option: any = await this.apollo
        .query({
          query: gql`
            query {
              projectSelectOptions {
              ${optionName} {
                ...${opt.queryFragment}
              }
            }
          }
          ${opt.queryFragment}
        `,
        })
        .pipe(map((result) => result.data))
        .toPromise();

      return option.projectSelectOptions[optionName];
    } catch (error) {
      console.error(error);
    }
  }
}
