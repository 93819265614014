<mat-menu #userMenu="matMenu">
  <button mat-menu-item (click)="auth.logOut()">
    Sign out
  </button>
</mat-menu>

<button mat-button [matMenuTriggerFor]="userMenu" *ngIf="user">
  {{ user.full_name }}

  <img
    alt="User Avatar"
    class="user-avatar img-circle"
    id="user-image"
    [src]="userImage"
    *ngIf="userImage"
  />

  <i class="fal fa-caret-down fa-lg user-options"></i>
</button>
