import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class PrimeDateUtils {
  private standardDateFormat = 'yyyy-MM-dd';

  constructor(@Inject(LOCALE_ID) private locale) {}

  convertToPrimeFormat(date: string | Date): string {
    if (date) {
      return formatDate(date, this.standardDateFormat, this.locale);
    }

    return null;
  }

  today(): string {
    return formatDate(new Date(), this.standardDateFormat, this.locale);
  }

  primeFormatToDate(date: string): Date {
    const split = date.split('-');
    const year = parseInt(split[0]);
    const month = parseInt(split[1]) - 1;
    const day = parseInt(split[2]);
    const jsDate = new Date(year, month, day);
    return jsDate;
  }
}
