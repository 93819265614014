<div class="prime-menu-list">
  <div class="prime-menu-item cursor-pointer" *ngIf="layout.isMobile">
    <span class="cursor-pointer header-logo">
      <img src="/apps/prime/src/assets/PRIMe Lite-Transparent.png" height="35" alt="PRIMe" />
    </span>
  </div>

  <ng-content class="prime-menu-item" select=".menu-content"></ng-content>

  <div
    *ngFor="let item of menuItems; let i = index"
    class="prime-menu-item cursor-pointer"
    [ngClass]="{ 'selected-nav': i === selectedNavIndex }"
    (click)="setSelectedNav(i)"
  >
    <span class="prime-menu-item-label">
      {{ item.label }}
    </span>

    <i *ngIf="item.iconClass" class="{{ item.iconClass }}"></i>
  </div>

  <div class="nav-logos">
    <h4 class="header-text">Powered By:</h4>

    <img class="vic-logo" src="/assets/logo.png" alt="Victaulic Logo" />
    <img class="dc-logo" src="/assets/dc-piping.png" alt="DC Piping Logo">
  </div>
</div>
