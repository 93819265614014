import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'prime-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss'],
})
export class LoadingButtonComponent implements OnInit {
  @Output() buttonClicked = new EventEmitter<boolean>();
  @Input() buttonText: string;
  @Input() loading = false;
  @Input() color = 'accent';

  constructor() {}

  ngOnInit(): void {}

  onClicked() {
    // Prevent multiple emits since used for loading something
    // Parent component responsible for updating state
    if (!this.loading) {
      this.buttonClicked.emit(true);
    }
  }
}
