import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Project } from "@prime/types";
import {VdcUsersClientMutationService} from 'libs/client/services/vdc-user-services/src/lib/vdc-users-client-mutation.service'

@Component({
  selector: "prime-user-recent-projects",
  templateUrl: "./user-recent-projects.component.html",
  styleUrls: ["./user-recent-projects.component.scss"],
})
export class UserRecentProjectsComponent implements OnInit {
  @Input()
  recentProjects: Project[] = [];
  @Input()
  @Output() projectSelected = new EventEmitter<number>();

  constructor(private userService: VdcUsersClientMutationService) { }

  ngOnInit(): void { }

  onProjectSelected(proj_id: number) {
    this.projectSelected.next(proj_id);
  }

  getOpenChangeOrders(project: Project): number {
    return project.changeOrders?.filter(x => x.change_order_status_id === 3)?.length;
  }

  getClosedChangeOrders(project: Project): number {
    return project.changeOrders?.filter(x => x.change_order_status_id === 7)?.length;
  }

  getInReviewChangeOrders(project: Project): number {
    return project.changeOrders?.filter(x => x.change_order_status_id === 9)?.length;
  }

  public projectHasValidChangeOrders(project: Project): boolean {
    return project?.changeOrders?.find(x => x.change_order_id > 0 && [3, 7, 9].includes(x.change_order_status_id)) !== undefined;
  }

  setFavorite(project){
    project.isFavorite = !project.is_favorite;
    this.userService.updateUserFavorite(project.proj_id, project.isFavorite).subscribe(data =>{
      location.reload();
    });
  }
}
