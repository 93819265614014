import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { ProjectCardComponent } from "./project-card/project-card.component";

@NgModule({
  imports: [CommonModule, MatCardModule],
  declarations: [ProjectCardComponent],
  exports: [ProjectCardComponent],
})
export class ClientUiProjectCardModule {}
