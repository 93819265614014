import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AuthService } from '@prime/client/services/client-auth';
import { LayoutService } from '@prime/client/services/utils';
import { MenuListComponent } from '@prime/client/ui/menu-list';
import { MenuItem, User } from '@prime/types';
import { Subscription } from 'rxjs';
import { MainNavOptions } from '../routes/main-nav-options';
import { interval } from 'rxjs';
import { VdcDialogService } from '@prime/client/services/dialogs';

@Component({
  selector: 'prime-prime-container',
  templateUrl: './prime-container.component.html',
  styleUrls: ['./prime-container.component.scss'],
})
export class PrimeContainerComponent implements OnDestroy {
  toolbarHidden: boolean = false
  @ViewChild(MenuListComponent) menuList: MenuListComponent;
  showMenuOption = true;
  opened = true;
  @ViewChild('sideNav', { static: false }) sideNav: MatSidenav;
  @ViewChild(MatSidenavContainer) sidenavContainer: MatSidenavContainer;
  hasBackdrop = true;
  sideNavMode = 'over';
  selectedNavIdx = 0;
  menuItems: MenuItem[];
  userResolved = false;
  loggedIn: boolean = false;
  forgotPasswordMode: boolean = false;

  loginForm: FormGroup;
  forgotPasswordForm: FormGroup;

  private navs: MenuItem[] = MainNavOptions;
  private _subs = new Subscription();

  constructor(
    private authService: AuthService,
    private router: Router,
    public layout: LayoutService,
    public formBuilder: FormBuilder,
    private vdcDialogs: VdcDialogService
  ) {

    const emailPattern: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    //set up the login form
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.pattern(emailPattern)]],
      password: ['', [
          Validators.required,
        ],
      ],
      email_address: ['']
    }, {updateOn: 'change'});

    // set up the forgot password form.
    this.forgotPasswordForm = this.formBuilder.group({
      email_address: ['', [Validators.required, Validators.pattern(emailPattern)]],
    }, {updateOn: 'change'})

    this._subs.add(this.authService.watchCurrentUser().subscribe((user) => {
      if (user && user?.user_id) {
        this.userResolved = true;
        this.loggedIn = true;
        const sub1 = this.layout.currentLayout.subscribe((layout) => {
          if (this.layout.isMobile) {
            this.hasBackdrop = true;
            this.sideNavMode = 'over';
          }
        });

        this._subs.add(sub1);

        if (!this.authService.isAdmin()) {
          this.navs.splice(1, 1);
        }
        this.menuItems = this.navs;

        const sub2 = this.router.events.subscribe((evt) => {
          if (evt instanceof NavigationEnd && evt.url !== '/') {
            this.opened = false;
            this.restoreNavLink();
          }

          if (evt instanceof NavigationEnd && evt.url === '/') {
            this.opened = true;
            this.selectedNavIdx = 0;
          }

          if (evt instanceof NavigationEnd) {
            this.toggleToolbar(evt.url);
          }
        });
        this._subs.add(sub2);
      }else{
        this.authService.logOut();
        this.loggedIn = false;
      }
    }));
  }

  private toggleToolbar(url: string) {
    if (url.includes('vdc-tv')) {
      this.toolbarHidden = true;
    }
    else {
      this.toolbarHidden = false;
    }
  }

  private restoreNavLink() {
    const url = this.router.url.split('/');
    const navLink = url[1];
    this.menuItems.forEach((itm, idx) => {
      if (itm.link === navLink) {
        this.selectedNavIdx = idx;
      }
    });
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  toggleSideNav() {
    this.sideNav.toggle();
  }

  goToHome() {
    this.router.navigate(['']);
  }

  onProjectSelected(proj_id: number) {
    this.router.navigate(['project', proj_id]);
  }

  // check user credentials. 
  // TODO: create an error message when user enters incorrect credent
  checkLogin(){
    if(this.loginForm.invalid) { return };
    // check the login credentials for user. 
    this.loginForm.patchValue({'email_address': this.loginForm.controls.username.value})
    this._subs.add(this.authService.checkLogin(this.loginForm.value).subscribe({
      next: (User: User) =>{
        this.loggedIn = true;
        this.authService.setUser(User);
      },
      error: err =>{
        console.error(err);
      }
    }));
  }

  // register user to from the provided form.
  forgotPassword(){
    if(this.forgotPasswordForm.invalid) { return };
    this._subs.add(this.authService.forgotPassword(this.forgotPasswordForm.value).subscribe({
      next: (res: User) =>{        
        this.vdcDialogs.showMessage({header: 'Success', message: 'Please check your email for a confirmation message.'});
        // send user back to log in form.
        this.forgotPasswordMode = false;
      }, 
      error: err => console.error(err)
    }));
  }

  // log user out on click
  logOut(){ 
    this.authService.logOut(); 
    this.loggedIn = false;

    //reset form values 
    this.loginForm.patchValue({
      'username' : '',
      'password': '',
      'email_address': ''
    })
  }
}
