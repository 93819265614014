import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prime-nav-overlay',
  templateUrl: './nav-overlay.component.html',
  styleUrls: ['./nav-overlay.component.scss'],
})
export class NavOverlayComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
