import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class OverlayMessageService {
  private _overlay = new Subject<boolean>();
  get overlay() {
    return this._overlay.asObservable();
  }

  // constructor() {}

  setOverlay() {
    this._overlay.next(true);
  }

  dismissOverlay() {
    this._overlay.next(false);
  }
}
