import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastController, AlertController } from '@ionic/angular';
import { ConfirmationDialogParams } from './confirmation-dialog-params';
import { VdcErrorDialogComponent } from './vdc-error-dialog/vdc-error-dialog.component';
import { VdcLoadingDialogComponent } from './vdc-loading-dialog/vdc-loading-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface ClickPosition {
  left: string;
  top: string;
}

@Injectable({ providedIn: 'root' })
export class VdcDialogService {
  isLoading = false;
  loadingDialogRef: MatDialogRef<VdcLoadingDialogComponent>;
  errorDialogRef: MatDialogRef<VdcErrorDialogComponent>;

  // Attempted hack work around for Angular change detection. Just showing simple info dialogs
  private dialogDelay = 10;

  constructor(
    private dialogService: MatDialog,
    private toastController: ToastController,
    private alertController: AlertController,
    private snackBar: MatSnackBar
  ) { }

  startLoading(timeoutTime?) {
    // Attempting to help clients with afterChecked Angular errors in lifecycle hooks
    setTimeout(() => {
      this.loadingDialogRef = this.dialogService.open(VdcLoadingDialogComponent, {
        width: '250px',
        height: '250px',
        panelClass: 'dialog',
      });
      if (timeoutTime) {
        this.loadingDialogRef.afterOpened().subscribe((_) => {
          setTimeout(() => {
            this.loadingDialogRef.close();
          }, timeoutTime);
        });
      }
    }, this.dialogDelay);
  }

  closeAll() {
    this.loadingComplete();
  }

  loadingComplete() {
    setTimeout(() => {
      this.dialogService.closeAll();
    }, 1);
  }

  async updateComplete() {
    const toast = await this.toastController.create({
      message: 'Update Complete',
      duration: 2000,
      cssClass: 'update-toast',
    });
    toast.present();
  }

  showError(
    message?: string,
    noCloseAll?: boolean,
    size: { width: string; height: string } = {
      width: '325px',
      height: '175px',
    }
  ) {
    this.errorDialogRef = this.dialogService.open(VdcErrorDialogComponent, {
      width: size.width,
      height: size.height,
      data: {
        message: message || 'An error occured.',
      },
    });

    const errorSub = this.errorDialogRef.afterClosed().subscribe((result) => {
      errorSub.unsubscribe();
      // Close all open dialogs after user acknowledges error by default
      if (!noCloseAll) {
        this.dialogService.closeAll();
      }
    });
  }

  async showConfirmationDialog(options: ConfirmationDialogParams, customCssClass?: string): Promise<boolean> {
    const alert = await this.alertController.create({
      header: options.header,
      cssClass: customCssClass,
      subHeader: options.subHeader,
      message: options.message,
      buttons: options.buttons || [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Yes',
          role: 'confirmed',
        },
      ],
    });

    await alert.present();
    const result = await alert.onWillDismiss();
    if (result.role === 'confirmed') {
      return true;
    }
    if (result.role === 'no') {
      return false;
    }
    return;
  }

  async showMessage(options: ConfirmationDialogParams, awaitModalClose?: boolean) {
    const alert = await this.alertController.create({
      header: options.header,
      subHeader: options.subHeader,
      message: options.message,
      buttons: [
        {
          text: 'Close',
          role: 'confirmed',
        },
      ],
    });
    await alert.present();
    if (awaitModalClose) {
      await alert.onWillDismiss();
    }
  }

  /**
   * A function that will create a snackbar on the screen for you.
   * @param message A message that will show on the snackbar.
   * @param button The text that will show on the right side of the snackbar.
   * @param openDuration The duration in ms that the snackbar will stay open.
   */
  staticSnackBarMessage(message: string, button: string = '') {
    this.snackBar.open(message, button);
  }

  snackBarMessage(message: string, action: string = '', openDuration?: number) {
    this.snackBar.open(message, action, { duration: openDuration || 2000 });
  }

  getDialogPositionFromClick(event): ClickPosition {
    event.stopPropagation();
    return {
      left: event.clientX - 150 + 'px',
      top: event.clientY + 20 + 'px',
    };
  }

  /**
   * Returns an array of all the open dialogs
   */
  get openDialogs(): MatDialogRef<any, any>[] {
    return this.dialogService.openDialogs;
  }
}
