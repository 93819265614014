import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap, finalize } from "rxjs/operators";
import { OverlayMessageService } from "@prime/client/services/utils";
import { VdcDialogService } from "@prime/client/services/dialogs";

@Injectable({ providedIn: "root" })
export class LoadingInterceptor implements HttpInterceptor {
  constructor(
    private loader: OverlayMessageService,
    private dialogs: VdcDialogService
  ) { }
  private totalRequests = 0;

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.totalRequests === 0 && req?.body?.operationName !== 'getVdcTvData') {
      this.loader.setOverlay();
    }
    this.totalRequests++;

    return next.handle(req).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.loader.dismissOverlay();
        }
      }),
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          if (evt?.body?.message) {
            this.dialogs.snackBarMessage(evt?.body?.message, "Close");
          }
        }
      })
    );
  }
}
