import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';
import { UiLayouts } from './ui-layouts';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LayoutService {
  private _currentLayoutType = UiLayouts.Desktop;
  private _currentLayout = new BehaviorSubject<string>(this._currentLayoutType);
  get currentLayout() {
    return this._currentLayout.asObservable();
  }

  /**
   * Properties set to be read by components to reduce added code they need for checking current layout type.
   */
  private _isMobile = false;
  get isMobile() {
    return this._isMobile;
  }

  private _isTablet = false;
  get isTablet() {
    return this._isTablet;
  }

  private _isDesktop = true;
  get isDesktop() {
    return this._isDesktop;
  }

  constructor(private breakpointObserver: BreakpointObserver) {
    this.watchScreenSizes();
  }

  private watchScreenSizes() {
    this.breakpointObserver
      .observe([Breakpoints.Large])
      .pipe(filter((state) => state.matches))
      .subscribe((state: BreakpointState) => {
        console.log('Layout in Desktop mode');
        this._isDesktop = true;
        this._isMobile = false;
        this._isTablet = false;
        this.setCurrentLayoutMode(UiLayouts.Desktop);
      });

    this.breakpointObserver
      .observe([Breakpoints.XLarge])
      .pipe(filter((state) => state.matches))
      .subscribe((state: BreakpointState) => {
        console.log('Layout in XLarge Desktop mode');
        this._isDesktop = true;
        this._isMobile = false;
        this._isTablet = false;
        this.setCurrentLayoutMode(UiLayouts.DesktopLarge);
      });

    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait])
      .pipe(filter((state) => state.matches))
      .subscribe((state: BreakpointState) => {
        console.log('Layout in Mobile mode: Portrait');
        this._isMobile = true;
        this._isDesktop = false;
        this._isTablet = false;
        this.setCurrentLayoutMode(UiLayouts.HandsetPortrait);
      });

    this.breakpointObserver
      .observe([Breakpoints.HandsetLandscape])
      .pipe(filter((state) => state.matches))
      .subscribe((state: BreakpointState) => {
        console.log('Layout in Mobile mode: Landscape');
        this._isMobile = true;
        this._isDesktop = false;
        this._isTablet = false;
        this.setCurrentLayoutMode(UiLayouts.HandsetLandscape);
      });

    this.breakpointObserver
      .observe([Breakpoints.Tablet, Breakpoints.TabletLandscape])
      .pipe(filter((state) => state.matches))
      .subscribe((state: BreakpointState) => {
        console.log('Layout in Tablet mode: Landscape');
        this._isTablet = true;
        this._isMobile = false;
        this._isDesktop = false;
        this.setCurrentLayoutMode(UiLayouts.TabletLandscape);
      });

    this.breakpointObserver
      .observe([Breakpoints.TabletPortrait])
      .pipe(filter((state) => state.matches))
      .subscribe((state: BreakpointState) => {
        console.log('Layout in Tablet mode: Portrait');
        this._isTablet = true;
        this._isMobile = false;
        this._isDesktop = false;
        this.setCurrentLayoutMode(UiLayouts.TabletPortrait);
      });
  }

  private setCurrentLayoutMode(layout: string) {
    this._currentLayoutType = layout;
    this._currentLayout.next(this._currentLayoutType);
  }
}
