<div>
  <p>
    <i class="far fa-exclamation-triangle fa-lg"></i>
  </p>
  <p class="bold">
    {{ data.message }}
  </p>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close color="warn">Close</button>
</mat-dialog-actions>
