import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PrimeContainerComponent } from '../prime-container/prime-container.component';
import { PrimeUserRecentProjectsViewComponent } from '../prime-user-recent-projects-view/prime-user-recent-projects-view.component';
import { AdminDashboardGuard } from '../guards/admin-dashboard.guard';
import { AuthGuardService } from '@prime/client/services/client-auth';
@NgModule({
  imports: [
    RouterModule.forRoot([
    {
        path: '',
        component: PrimeContainerComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                component: PrimeUserRecentProjectsViewComponent,
            },
            {
                path: 'project',
                loadChildren: () => import('../@project-management/project-management.module').then((mod) => mod.ProjectManagementModule),
            },
            {
                path: 'admin-board',
                loadChildren: () => import('../@admin-board/admin-board.module').then((mod) => mod.AdminBoardModule),
                canActivate: [
                    AdminDashboardGuard,
                ],
            },
            {
                path: 'manage-billings',
                loadChildren: () => import('../@billing/manage-billings.module').then((mod) => mod.ManageBillingsModule),
            },
            {
                path: 'new-project-entry',
                loadChildren: () => import('../@project-creation/project-creation.module').then((mod) => mod.ProjectCreationModule),
            },
            {
                path: 'pre-bid',
                loadChildren: () => import('../@pre-bid/pre-bid.module').then((mod) => mod.PreBidModule),
            },
            {
                path: 'project-health',
                loadChildren: () => import('../@project-health/project-health.module').then((mod) => mod.ProjectHealthModule),
            },
            {
                path: 'resource-management',
                data: {
                    preload: false,
                },
                loadChildren: () => import('../@scheduling/@resource-management/resource-management.module').then((m) => m.ResourceManagementModule),
            },
            {
                path: 'teams-dashboard',
                loadChildren: () => import('../@teams-dashboard/teams-dashboard.module').then((mod) => mod.TeamsDashboardModule),
            },
            {
                path: 'time-tracker',
                loadChildren: () => import('../@time-tracker/time-tracker.module').then((m) => m.TimeTrackerModule),
            },
            {
                path: 'vdc-tv',
                loadChildren: () => import('../@vdc-tv/vdc-tv.module').then((m) => m.VdcTvModule),
            },
        ],
    },
    {
        path: 'project-material',
        loadChildren: () => import('../@material/project-material.module').then((mod) => mod.ProjectMaterialModule),
    },
    {
        path: 'forgot-password-submission',
        loadChildren: () => import('../@forgot-password/forgot-password.module').then((mod) => mod.ForgotPasswordModule),
    },
], { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
