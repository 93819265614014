import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class DynamicAssetLoaderService {
  private lazyMap = {};

  /**
   * Allows lazy loading of stylesheets and scripts as needed to keep bundle sizes down and help prevent long load times.
   */
  constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * Adds a new link element to load CSS dynamically into the document.
   * Internal map of lazy loaded styles is kept. If script style has already been loaded the method simply returns.
   * @param cssFileName
   */
  loadStyle(cssFileName: string) {
    return new Promise((resolve, reject) => {
      if (this.lazyMap[cssFileName]) {
        console.log(`'${cssFileName}' already loaded`);
        return resolve(true);
      }
      this.lazyMap[cssFileName] = cssFileName;
      const head = this.document.getElementsByTagName('head')[0];
      const style = this.document.createElement('link');
      style.rel = 'stylesheet';
      style.href = `${cssFileName}`;
      style.onload = () => {
        resolve(true);
      };
      style.onerror = () => {
        console.error(`Error loading styles: '${cssFileName}'`);
        reject();
      };
      head.appendChild(style);
    });
  }

  loadScript(src: string) {
    return new Promise<boolean>((resolve, reject) => {
      if (this.lazyMap[src]) {
        console.log(`'${src}' already loaded`);
        return resolve(true);
      }
      this.lazyMap[src] = src;
      const head = this.document.getElementsByTagName('head')[0];
      const script = this.document.createElement('script');
      script.src = src;
      script.type = 'module';
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        console.error(`Error loading script: '${src}'`);
        reject();
      };
      head.appendChild(script);
    });
  }

  isLoaded(src: string) {
    return this.lazyMap[src];
  }
}
