import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '@prime/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { MS_GRAPH_ENDPOINT } from '@prime/client/constants';
import { ClientEnvironmentService } from '@prime/client/env';
import * as msal from '@azure/msal-browser';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private msalInstance: msal.PublicClientApplication;
  private account: msal.AccountInfo;
  private authResult: msal.AuthenticationResult;
  private _user: User = {};
  private _currentUser = new BehaviorSubject<User>(null);
  private requestScopes = {
    scopes: ['user.read', 'email', 'profile', 'offline_access'],
  };
  private _jwtService: JwtHelperService;

  watchCurrentUser() {
    return this._currentUser.asObservable();
  }

  get currentUser() {
    return {
      ...this._user,
    };
  }

  constructor(private http: HttpClient, private clientEnv: ClientEnvironmentService, private _router: Router) {
    this._jwtService = new JwtHelperService();
  }

  public async init(vdcUsersOnly: boolean) {
    //try {
    //   const msalConfig = {
    //     auth: {
    //       clientId: '47d83043-b993-476d-aa2c-f61f602e94f4',
    //       authority: 'https://login.microsoftonline.com/d2bfb7fe-352c-4886-98a3-044da283af7e',
    //     },
    //     cache: {
    //       cacheLocation: 'localStorage',
    //     },
    //   };

    //   this.msalInstance = new msal.PublicClientApplication(msalConfig);
    //   const authResult = await this.msalInstance.handleRedirectPromise();
    //   if (authResult) {
    //     console.log('Setting auth result:');
    //     this.setAuthResult(authResult);
    //   } else {
    //     console.log('User auth response is null. User already logged in.');
    //   }

    //   const account = this.getAccount();
    //   if (!account) {
    //     this.login(vdcUsersOnly);
    //   } else {
    //     await this.setAccount(vdcUsersOnly);
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
    let token = this.primeLSAccessToken;
    if(token) {
      let user = this._jwtService.decodeToken(token);
      this.setUser(user, false);
    }
  }

  // private getSilentToken() {
  //   /**
  //    * Try to silently refresh any tokens at this point to avoid having the user login again.
  //    * If that fails then manual login is needed again.
  //    */
  //   return this.msalInstance
  //     .acquireTokenSilent(this.requestScopes)
  //     .then((tokenResponse) => {
  //       localStorage.setItem('msoft_token_response', JSON.stringify(tokenResponse));
  //       console.log('Acquired user token silently.');
  //       this._user.microsoft_graph_token = tokenResponse.accessToken;
  //     })
  //     .catch((error) => {
  //       console.log('acquireTokenSilent error');

  //       if (error instanceof msal.InteractionRequiredAuthError) {
  //         console.log('Error IS InteractionRequiredAuthError');
  //         console.error(error);
  //         // fallback to interaction when silent call fails
  //         return this.msalInstance.loginRedirect(this.requestScopes);
  //       } else {
  //         console.log('Error is NOT InteractionRequiredAuthError');
  //         console.error(error);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }

  // async login(vdcUsersOnly) {
  //   await this.msalInstance.loginRedirect(this.requestScopes);
  //   await this.setAccount(vdcUsersOnly);
  // }

  // private async setAccount(vdcUsersOnly: boolean) {
  //   try {
  //     this.account = this.getAccount();
  //     // console.log(this.account);
  //     this.msalInstance.setActiveAccount(this.account);
  //     // await this.getSilentToken();
  //     if (vdcUsersOnly) {
  //       await this.setVdcUser();
  //       // await Promise.all([this.getSilentToken(), this.setVdcUser()]);
  //       this._currentUser.next(this.currentUser);
  //     }
  //   } catch (error) {
  //     console.log('setAccount Error:');
  //     console.error(error);
  //   }
  // }

  // private setAuthResult(authResult: msal.AuthenticationResult) {
  //   console.log(authResult);
  //   this.authResult = authResult;
  // }

  /**
   * After we have user Microsoft data
   */
  private async setVdcUser() {
    // Get and set the vdc user info
    const vdcUserData = await this.http
      .get(this.clientEnv.env.ENDPOINT_BASE + '/users/microsoft/' + this.account.localAccountId)
      .toPromise();
    this._user = { ...vdcUserData, ...this._user };
    console.log(this._user);
    sessionStorage.setItem('prime_access_token', this._user.access_token);
    localStorage.setItem('prime_access_token', this._user.access_token);
  }

  setUser(user: User, setStorage: boolean = true){
    this._user = user;
    if(setStorage){
      this.setTokens(user);
    }

    this._currentUser.next(this.currentUser);
  }

  isAuthenticated() {
    return this._currentUser !== null;
    //return this.getAccount() !== null;
  }

  getAccount() {
    // need to call getAccount here?
    const currentAccounts = this.msalInstance.getAllAccounts();
    if (currentAccounts === null) {
      console.log('No accounts detected');
      return null;
    }

    if (currentAccounts.length > 1) {
      // Add choose account code here
      console.log('Multiple accounts detected, need to add choose account code.');
      return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }
  }

  getLocalAccessToken() { return this.primeLSAccessToken || this.primeSSAccessToken || ''; }
  getSalesPortalAccessToken() { return localStorage.getItem('sales_portal_access_token'); }

  validateToken(token: string): { expired: boolean, decoded: { id: number, iat: number, exp: number } } {
    try {
      const decoded = this._jwtService.decodeToken(token);
      decoded.id = parseInt(decoded.id);
      const expired = this._jwtService.isTokenExpired(token);
      localStorage.setItem('sales_portal_access_token', token);
      return { decoded, expired };
    } catch (error) {
      console.error(error);
      return;
    }
  }

  decodeToken(token: string){
    return this._jwtService.decodeToken(token);
  }

  async getUserProfileImage() {
    // try {
    //   const blob = await this.http
    //     .get(MS_GRAPH_ENDPOINT + '/photos/48x48/$value', {
    //       headers: new HttpHeaders()
    //         .set('Authorization', `Bearer ${this._user.microsoft_graph_token}`)
    //         .set('Content-Type', 'image/jpeg'),
    //       responseType: 'blob',
    //     })
    //     .toPromise();
    //   const urlCreator = window.URL || window.webkitURL;
    //   const url = urlCreator.createObjectURL(blob);
    //   return url;
    // } catch (error) {
    //   console.error(error);
    // }
  }

  /**
   * If our current account reference is not passed the user can be signed out of other Microsoft accounts as well.
   */
  // async logout() {
  //   await this.msalInstance.logout({
  //     account: this.account,
  //   });
  // }

  logOut(){
    localStorage.removeItem('prime_access_token');
    sessionStorage.removeItem('prime_access_token');
    localStorage.removeItem('refresh_token');
    sessionStorage.removeItem('refresh_token');

    // send user back to log in screen.
    this._router.navigate(['']);
  }

  isAdmin() {
    return this._user.user_type >= 4;
  }

  isSuperAdmin() {
    return this._user.user_type >= 5;
  }

  isActive() {
    return this._user.status === 1;
  }

  // check user login credentials 
  checkLogin(userCredentials: {email_address: string, password: string, username: string}): Observable<User>{
    if(!userCredentials) { return }
    return this.http.post<User>(`${this.clientEnv.env.ENDPOINT_BASE}/users/login`, userCredentials);
  }

  // send email to user's email address. 
  forgotPassword(userCredentials: {email_address: string}): Observable<User>{
    if(!userCredentials){ return }
    return this.http.post<User>(`${this.clientEnv.env.ENDPOINT_BASE}/users/forgot-password`, userCredentials);
  }

  // reset user's password 
  resetPassword(userCredentials: {password: string, token: string}): Observable<any>{
    if(!userCredentials) return;
    return this.http.post<any>(`${this.clientEnv.env.ENDPOINT_BASE}/users/forgot-password-submission`, userCredentials);
  }

  // get refresh token when a jwt token expires.
  getRefreshToken(){ 
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders({
        'refresh-token': this.primeLSRefreshToken,
      }), 
    };

    return this.http.get<any>(`${this.clientEnv.env.ENDPOINT_BASE}/users/refreshToken`, requestOptions);
  }

  setTokens(user){
    sessionStorage.setItem('prime_access_token',user.access_token);
    localStorage.setItem('prime_access_token',user.access_token);
    sessionStorage.setItem('refresh_token',user.refresh_token);
    localStorage.setItem('refresh_token',user.refresh_token);
  }

  // getters for prime_access_token
  get primeLSAccessToken(){ return localStorage.getItem('prime_access_token'); }
  get primeSSAccessToken(){ return sessionStorage.getItem('prime_access_token'); }
  get primeLSRefreshToken(){ return localStorage.getItem('refresh_token')}
}
