import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ProjectSearchListComponent } from '../project-search-list/project-search-list.component';
import { ProjectSearchInput } from '@prime/types';
import { ProjectBaseQueryService } from '@prime/client/services/project-base';
import { ModalController } from '@ionic/angular';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectSelectDialogComponent } from '../project-select-dialog/project-select-dialog.component';

@Component({
  selector: 'prime-project-search-input',
  templateUrl: './project-search-input.component.html',
  styleUrls: ['./project-search-input.component.scss'],
})
export class ProjectSearchInputComponent {
  @Output() projectSelected = new EventEmitter<number>();
  fetching = false;
  loadingAdvanced = false;
  searchInputControl = new FormControl();
  dialogRef: MatDialogRef<ProjectSelectDialogComponent>;

  constructor(
    private dialog: MatDialog,
    private projQuery: ProjectBaseQueryService,
    private modalController: ModalController
  ) {}

  showAdvancedSearchDialog() {
    this.loadingAdvanced = true;
    this.dialogRef = this.dialog.open(ProjectSelectDialogComponent, {
      width: '1100px',
      height: '750px',
    });

    this.dialogRef.afterOpened().subscribe(() => {
      this.loadingAdvanced = false;
    });

    const dialogSub = this.dialogRef.afterClosed().subscribe((project) => {
      dialogSub.unsubscribe();
      if (project) {
        this.projectSelected.next(project.proj_id);
      }
    });
  }

  async search() {
    if (!this.searchInputControl.dirty) {
      return;
    }

    this.fetching = true;

    const searchFields: ProjectSearchInput = {
      project_name_or_number: this.searchInputControl.value,
    };

    const results = await this.projQuery.searchProjects(searchFields);
    this.fetching = false;
    this.searchInputControl.reset();
    this.showResults(results);
  }

  async showResults(results) {
    const modal = await this.modalController.create({
      component: ProjectSearchListComponent,
      componentProps: {
        resultSet: results,
        modal: true,
      },
      cssClass: 'search-list-dialog',
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.projectSelected.next(data.proj_id);
    }
  }
}
