import { Injectable } from "@angular/core";
import { ProjectSelectOptions } from "@prime/types";
import { VirtualSelectOption } from "@prime/client/ui/virtual-select";
import { OptionsUiMap } from "./options-map";
import { Apollo } from "apollo-angular";
import {
  GetCommonSelectOptionsDocument,
  GetCommonSelectOptionsQuery,
} from "@prime/client/services";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class OptionsUiUtilService {
  private selectOptions: ProjectSelectOptions = {};
  private options: Array<{
    key: string;
    options: VirtualSelectOption[];
    values: any[];
  }> = [];

  constructor(private apollo: Apollo) {
    this.apollo
      .watchQuery<GetCommonSelectOptionsQuery>({
        query: GetCommonSelectOptionsDocument,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data.projectSelectOptions))
      .subscribe((opts) => {
        this.addCurrentOptions(opts);
      });
  }

  // private loadCommonOptions() {
  //   this.apollo
  //     .watchQuery<GetCommonSelectOptionsQuery>({
  //       query: GetCommonSelectOptionsDocument,
  //     })
  //     .valueChanges.pipe(map((result) => result.data.projectSelectOptions))
  //     .subscribe((opts) => {
  //       this.addCurrentOptions(opts);
  //     });
  // }

  addCurrentOptions(options: ProjectSelectOptions) {
    this.selectOptions = options;

    for (const opt of OptionsUiMap) {
      // Store any previous reference but avoid duplicate entries.
      if (!this.options.find((op) => op.key === opt.optionName)) {
        const options = this.setOptionForUI(
          opt.optionName,
          opt.labelProp,
          opt.valueProp
        );

        if (options) {
          this.options.push({
            key: opt.optionName,
            options,
            values: this.getOptionValues(opt.optionName),
          });
        }
      }
    }
  }

  getOptionValues(optionName: string) {
    return this.selectOptions[optionName];
  }

  private setOptionForUI(
    optionName: string,
    labelProp: string,
    valueProp: string
  ): VirtualSelectOption[] {
    const option = this.selectOptions[optionName];
    if (option) {
      // They are always list/arrays of items
      return option.map((type) => {
        return {
          label: type[labelProp],
          value: type[valueProp],
        };
      });
    }
  }

  filterOption(key: string, filterValue: string): VirtualSelectOption[] {
    const options = this.getUiOption(key);
    if (!filterValue) {
      return options;
    }

    return options.filter(
      (opt) => opt.label.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1
    );
  }

  getUiOption(key: string): VirtualSelectOption[] {
    const opt = this.options.find((op) => op.key === key);
    if (!opt) {
      throw new Error(`No OptionsUiMap entry for key ${key}`);
    }

    return opt.options;
  }
}
