import { MenuItem } from '@prime/types';

export const MainNavOptions: MenuItem[] = [
  {
    label: 'Home',
    iconClass: 'fal fa-home',
    link: '/',
  },
  {
    label: 'Admin Dashboard',
    iconClass: 'fal fa-calendar-alt',
    link: 'admin-board',
  },
  // {
  //   label: 'Collaboration Round Table',
  //   iconClass: 'fal fa-users',
  //   externalLink: 'https://app.smartsheet.com/dashboards/Cp8xVxjV4xGwXCfxV7j2f3mq56P9FFQCMRv9rVw1',
  // },
  // {
  //   label: 'Feed The CAAT',
  //   iconClass: 'fal fa-plus-circle',
  //   externalLink: 'https://app.smartsheet.com/b/form/6d9f569c8bd2442790b303dbb37e2b4a',
  // },
  // {
  //   label: 'Manage Billings',
  //   iconClass: 'fas fa-money-check',
  //   link: 'manage-billings',
  // },
  {
    label: 'New Project Entry',
    iconClass: 'fal fa-plus-circle',
    link: 'new-project-entry',
  },
  // {
  //   label: 'Pre-Bid Schedule',
  //   iconClass: 'fal fa-balance-scale',
  //   link: 'pre-bid',
  // },
  // {
  //   label: 'Project Health',
  //   iconClass: 'fal fa-heartbeat',
  //   link: 'project-health',
  // },
  // {
  //   label: 'Resource Management',
  //   iconClass: 'fal fa-calendar-alt',
  //   link: 'resource-management',
  // },
  // {
  //   label: 'Team Dashboard',
  //   iconClass: 'fal fa-tachometer',
  //   link: 'teams-dashboard',
  // },
  // {
  //   label: 'Time Sheet',
  //   iconClass: 'fal fa-calendar-alt',
  //   link: 'time-tracker',
  // },
  // {
  //   label: 'VDC TV',
  //   iconClass: 'far fa-tv',
  //   link: 'vdc-tv',
  //   // externalLink: 'http://vdc.victaulic.com/cps_carousel/index.php',
  // },
];
