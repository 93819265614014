import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prime-loading-dialog',
  templateUrl: './vdc-loading-dialog.component.html',
  styleUrls: ['./vdc-loading-dialog.component.scss'],
})
export class VdcLoadingDialogComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
