<div class="project-card" *ngIf="project">
  <div class="card-header">
    <div class="card-heading bold">
      {{ project.project_name }}
    </div>
    <div class="card-subtitle">
      {{ project.customer?.customer }}
    </div>
  </div>

  <div class="card-content">
    <ng-content></ng-content>
  </div>
</div>
