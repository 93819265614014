import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientUiLoadingSpinnerModule } from '@prime/client/ui/loading-spinner';
import { VdcErrorDialogComponent } from './vdc-error-dialog/vdc-error-dialog.component';
import { VdcLoadingDialogComponent } from './vdc-loading-dialog/vdc-loading-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    ClientUiLoadingSpinnerModule,
    MatDialogModule,
    MatSnackBarModule,
    MatButtonModule,
  ],
  declarations: [VdcErrorDialogComponent, VdcLoadingDialogComponent],
  exports: [VdcErrorDialogComponent, VdcLoadingDialogComponent],
})
export class ClientServicesVdcDialogsModule {}
