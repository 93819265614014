import { Component, OnInit } from '@angular/core';
import { User } from '@prime/types';
import { AuthService } from '@prime/client/services/client-auth';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'prime-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  user: User;
  userImage: SafeUrl;

  constructor(public auth: AuthService, private sanitizer: DomSanitizer) {}

  async ngOnInit() {
    this.auth.watchCurrentUser().subscribe((user) => {
      this.user = user;
      if (user && user.microsoft_graph_token) {
        this.setUserImage();
      } else {
        this.user = null;
        this.userImage = null;
      }
    });
  }

  async setUserImage() {
    // const profilePhoto = await this.auth.getUserProfileImage();
    // this.userImage = this.sanitizer.bypassSecurityTrustUrl(profilePhoto);
  }
}
