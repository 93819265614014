<div class="fill-container" *ngIf="this.loggedIn">
  <mat-toolbar [ngClass]="toolbarHidden ? 'hideToolbar' : 'showToolbar'">
    <span class="cursor-pointer" (click)="toggleSideNav()" *ngIf="showMenuOption">
      <i class="fal fa-bars app-menu-select"></i>
    </span>
    <span class="cursor-pointer header-logo" *ngIf="!layout.isMobile" (click)="goToHome()">
      <img src="../../assets/PRIMe Lite-Transparent.png" height="35" alt="PRIMe" />
    </span>

    <span class="spacer">
      <div class="header-content" *ngIf="layout.isDesktop || layout.isTablet">
        <prime-project-search-input
          (projectSelected)="onProjectSelected($event)"
        ></prime-project-search-input>
      </div>
    </span>
    <!-- <div class="user-menu">
      <prime-user-menu></prime-user-menu>
    </div> -->
    <h1 style="color: white; cursor: pointer;" (click)="logOut()">Log Out</h1>
  </mat-toolbar>

  <div class="side-nav-container fill-container">
    <mat-sidenav-container [hasBackdrop]="hasBackdrop" class="fill-container" *ngIf="userResolved">
      <mat-sidenav [mode]="sideNavMode" #sideNav [opened]="opened">
        <prime-menu-list [menuItems]="menuItems" [selectedNavIndex]="selectedNavIdx">
          <div class="menu-content" *ngIf="layout.isMobile">
            <prime-project-search-input
              (projectSelected)="onProjectSelected($event)"
            ></prime-project-search-input>
          </div>
        </prime-menu-list>
      </mat-sidenav>

      <mat-sidenav-content>
        <div class="window-content">
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>

    <div class="fill-container" *ngIf="!userResolved">
      <div>
        <h3 class="bold text-center">
          Loading user information..
        </h3>
        <prime-loading-spinner></prime-loading-spinner>
      </div>
    </div>
  </div>
</div>

<section class="sign-in-form" *ngIf="!this.loggedIn">
  <div class="two-column-layout">
    <!-- <div class="column bg-image">
      <h1>BIM Foundry</h1>
      <h2>Beyond seamless connections: Login to build your pipeline to success.</h2>
    </div> -->
    <div class="column">
      <div class="sign-in" >
        <div class="form-header">
          <img src="./../../assets/PRIMe Lite-Transparent.png" alt="helo?">
          <h1 class="header-text" *ngIf="forgotPasswordMode">Forgot Password</h1>
          <p class="sub-text">Victaulic software streamlines piping design and fabrication in CAD applications, saving time and improving accuracy.</p>
        </div>
        <form [formGroup]="loginForm" (ngSubmit)="checkLogin()" *ngIf="!forgotPasswordMode">
          <div class="form-block">
            <label for="username">Email</label>
            <input type="text" name="username" formControlName="username">
            <span class="validation-error" *ngIf="loginForm.get('username').hasError('required') && (loginForm.get('username')?.dirty || loginForm.get('username')?.touched)">Email is required<br></span>
            <span class="validation-error" *ngIf="loginForm.get('username').hasError('pattern')">Email is not valid.</span>
          </div>
          <div class="form-block">
            <div class="two-col-label">
              <label for="password">Password</label>
              <span *ngIf="!forgotPasswordMode" class="link" (click)="this.forgotPasswordMode = true">Forgot Password?</span>
            </div>
            
            <input type="password" name="password" formControlName="password">
            <span class="validation-error" *ngIf="loginForm.get('password').hasError('required') && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">Password is required<br></span>
            <span class="validation-error" *ngIf="loginForm.get('password').hasError('minlength') && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">Password must be at least 6 characters in length.</span>
            <span class="validation-error" *ngIf="loginForm.get('password').hasError('noNumber') && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">Password must contain a Number.</span>
            <span class="validation-error" *ngIf="loginForm.get('password').hasError('noSymbol') && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">Password must contain a symbol.</span>
            <span class="validation-error" *ngIf="loginForm.get('password').hasError('noUppercase') && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">Password must contain an Uppercase</span>
          </div>

          <button>Log In</button>

        </form>

        <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()" *ngIf="forgotPasswordMode">
          <div class="form-block">
            <label for="email_address">Email</label>
            <input type="text" name="email_address" formControlName="email_address">
            <span class="validation-error" *ngIf="forgotPasswordForm.get('email_address').hasError('pattern') && (forgotPasswordForm.get('email_address')?.dirty || forgotPasswordForm.get('email_address')?.touched)">Email is not valid.</span>
          </div>

          <button>Send Reset Link</button>

        </form>

       
        <h4 *ngIf="forgotPasswordMode">Have an account? <span class="link" (click)="this.forgotPasswordMode = false"><strong>Sign In</strong></span></h4>
      </div>
    </div>
  </div>
</section>