<div class="result-list">
  <table mat-table [dataSource]="resultSet" class="result-table">
    <ng-container matColumnDef="proj_num">
      <th mat-header-cell *matHeaderCellDef>Project #</th>
      <td mat-cell *matCellDef="let proj">{{ proj.proj_num }}</td>
    </ng-container>

    <ng-container matColumnDef="proj_name">
      <th mat-header-cell *matHeaderCellDef>Project Name</th>
      <td mat-cell *matCellDef="let proj">{{ proj.project_name }}</td>
    </ng-container>

    <ng-container matColumnDef="region">
      <th mat-header-cell *matHeaderCellDef>Region</th>
      <td mat-cell *matCellDef="let proj">{{ proj.region }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let proj">{{ proj.proj_status }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="navToProject(row)"></tr>
  </table>

  <p class="bold" style="font-size: 18px;" *ngIf="resultSet.length === 0">
    No results found
  </p>
</div>
