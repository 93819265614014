/**
 * Export of screen sizes we are concerned with for dynamic layouts in components
 */

export const UiLayouts = {
  HandsetPortrait: 'HandsetPortrait',
  HandsetLandscape: 'HandsetLandscape',
  TabletLandscape: 'TabletLandscape',
  TabletPortrait: 'TabletPortrait',
  Desktop: 'Desktop',
  DesktopLarge: 'DesktopLarge',
};
