import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MenuItem, Project } from '@prime/types';
import { LayoutService } from '@prime/client/services/utils';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'prime-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss'],
})
export class MenuListComponent implements OnInit, OnDestroy {
  @Input() menuItems: MenuItem[] = [];
  @Input() selectedNavIndex = -1;
  @Input() project: Project;

  constructor(
    public layout: LayoutService,
    private router: Router,
    private activeRoute: ActivatedRoute,
  ) { }

  ngOnInit() { }

  ngOnDestroy() {
    this.selectedNavIndex = -1;
  }

  async setSelectedNav(idx: number) {
    const item = this.menuItems[idx];
    if (item.externalLink) {
      return this.openExternal(item.externalLink);
    }
    const success = await this.router.navigate([item.link], { relativeTo: this.activeRoute });
    if (success) {
      this.selectedNavIndex = idx;
    }
  }

  openExternal(link: string) {
    window.open(link, '__blank');
  }
}
