import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { OptionKeys } from "./option-keys";

export interface OptionMap {
  optionName: string;
  labelProp: string;
  valueProp: string;
  fragmentName?: string;
  queryFragment?: DocumentNode;
}

export const OptionsUiMap: OptionMap[] = [
  {
    optionName: OptionKeys.Customers,
    labelProp: "customer",
    valueProp: "customer_id",
    fragmentName: "CustomerFragment",
    queryFragment: gql`
      fragment CustomerFragment on Customer {
        customer
        customer_id
      }
    `,
  },
  {
    optionName: OptionKeys.Engineers,
    labelProp: "name",
    valueProp: "engineerid",
  },
  {
    optionName: OptionKeys.Salemen,
    labelProp: "name",
    valueProp: "autoID",
  },
  {
    optionName: OptionKeys.Users,
    labelProp: "full_name",
    valueProp: "user_id",
  },
];
