import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClientEnvironmentService } from '@prime/client/env';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private http: HttpClient, private clientEnv: ClientEnvironmentService) { }

  /**
   * Calls an end point that will upload and read the file(s) on the backend. The server will then have the files data in memory.
   * @param formData: Encoded data of the file(s) to be uploaded
   * @param source: The source of the call. Will be used to decide what end point to call
   */
  async addFiles(formData: FormData, source: string) {
    return this.http
      .post<{ message: string; data: any }>(
        this.clientEnv.env.ENDPOINT_BASE + `/file-import/${source}/upload`,
        formData
      )
      .toPromise();
  }

  /**
 * Calls an end point that will remove an already added file to be uploaded
 * @param formData: Encoded data of the file(s) to be uploaded
 * @param source: The source of the call. Will be used to decide what end point to call
 */
  async removeFile(fileData, source: string) {
    return this.http
      .post<{ message: string; data: any }>(
        this.clientEnv.env.ENDPOINT_BASE + `/file-import/${source}/remove`,
        { fileName: fileData.name, fileSize: fileData.size, fileType: fileData.type },
      )
      .toPromise();
  }

  /**
   * Calls an end point that will then perform the required task on the data complied from the uploaded file(s)
   * @param source: The source of the call. Will be used to decide what end point to call
   */
  async uploadFiles(source: string, data: any) {
    return this.http
      .post<{ message: string; data: any }>(
        this.clientEnv.env.ENDPOINT_BASE + `/file-import/${source}/import`,
        { data }
      )
      .toPromise();
  }
}
