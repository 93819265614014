import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@prime/client/services/client-auth';

@Injectable({
  providedIn: 'root'
})
export class AdminDashboardGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
  ) { }

  canActivate() {
    const hasAccess = this.auth.currentUser.user_type >= 4;
    if (hasAccess) {
      return true;
    } else {
      this.router.navigate(['']);
    }
  }

}
