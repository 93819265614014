import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpEventType,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { VdcDialogService } from '@prime/client/services/dialogs';
import { AuthService } from '@prime/client/services/client-auth';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private vdcDialogs: VdcDialogService, private auth: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Attach the access token to all out going HTTP request for back end
    let authReq = req;
    const primeAuthToken = this.auth.getLocalAccessToken();
    if (primeAuthToken) {
      authReq = req.clone({
        headers: req.headers.set('prime-access-token', primeAuthToken),
      });
    }
    const salesAuthToken = this.auth.getSalesPortalAccessToken();
    if (salesAuthToken) {
      authReq = req.clone({
        headers: req.headers.set('sales-portal-access-token', salesAuthToken),
      });
    }
    return next.handle(authReq).pipe(
      tap((evt: HttpEvent<any>) => {
        if (evt && evt.type === HttpEventType.Response) {
          const res = evt as HttpResponse<any>;
          if (res.status === 401 || res.status === 403) {
            this.vdcDialogs.showError('(401) Unauthorized.');
          }
        }
      })
    );
  }
}
