import { Injectable } from '@angular/core';
import { AddNewCustomerInput, Customer } from '@prime/types';
import { AddNewCustomerGQL } from '@prime/client/services';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProjectSelectOptionsMutationService {
  constructor(private newCustomerGQL: AddNewCustomerGQL) {}

  createNewCustomer(input: AddNewCustomerInput): Promise<Customer[]> {
    return this.newCustomerGQL
      .mutate({
        input,
      })
      .pipe(map((results) => results.data.addNewCustomer.customers))
      .toPromise();
  }
}
