import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ProjectBaseQueryService } from '@prime/client/services/project-base';
import { ProjectSearchInput } from '@prime/types';
import { ProjectSelectOptionsQueryService } from '@prime/client/services/project-select-options';
import { ProjectSearchResult } from '@prime/client/services';

@Component({
  selector: 'prime-project-select-dialog',
  templateUrl: './project-select-dialog.component.html',
  styleUrls: ['./project-select-dialog.component.scss'],
})
export class ProjectSelectDialogComponent implements OnInit, OnDestroy {
  searchGroup: FormGroup;
  fetching = false;
  resultSet: ProjectSearchResult[] = [];
  displayedColumns = ['proj_num', 'proj_name', 'status', 'region'];
  searchSub: Subscription;
  selectOptions;
  searchInput: ProjectSearchInput = {};
  filteredCustomersOptions: any;
  filteredSalesmanOptions: any;

  get showResults(): boolean {
    return (!this.resultSet || this.resultSet.length === 0) && !this.fetching;
  }

  constructor(
    public dialogRef: MatDialogRef<ProjectSelectDialogComponent>,
    private fb: FormBuilder,
    private projQuery: ProjectBaseQueryService,
    private options: ProjectSelectOptionsQueryService
  ) {} 

  async ngOnInit() {
    this.searchGroup = this.fb.group({
      project_name_or_number: this.fb.control(''),
      salesman: this.fb.control(''),
      project_type: this.fb.control(''),
      customer: this.fb.control(''),
      region: this.fb.control(''),
      coordinator: this.fb.control(''),
      project_status: this.fb.control(''),
      market: this.fb.control(''),
      additional_project_type: this.fb.control(''),
      service: this.fb.control(''),
    });
    this.selectOptions = await this.options.getCommonSelectOptions();

    this.searchSub = this.searchGroup.valueChanges.subscribe((values) => {
      // tslint:disable-next-line: forin
      for (const ctl in this.searchGroup.controls) {
        const control = this.searchGroup.controls[ctl];
        if (control.dirty) {
          this.searchInput[ctl] = control.value;
        }
      }
    });

    this.filteredCustomersOptions = this.searchGroup.controls.customer.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value, 'customer'))
    );

    this.filteredSalesmanOptions = this.searchGroup.controls.salesman.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value, 'salesman'))
    );
  }

  ngOnDestroy() {
    this.searchSub.unsubscribe();
  }

  private _filter(value: string, source): string[] {
    const filterValue = value?.toString()?.toLowerCase();
    if (source === 'customer') {
      return this.selectOptions?.customers?.filter((option) =>
        option.customer.toLowerCase().includes(filterValue)
      );
    } else if (source === 'salesman') {
      return this.selectOptions?.salesmen?.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }

  displayCustomer = (customer_id: number): string => {
    return customer_id
      ? this.selectOptions?.customers?.find((x) => x.customer_id === customer_id).customer
      : '';
  };

  displaySalesman = (autoID: number): string => {
    return autoID ? this.selectOptions?.salesmen?.find((x) => x.autoID === autoID).name : '';
  };

  disableAll() {
    // tslint:disable-next-line: forin
    for (const ctl in this.searchGroup.controls) {
      const control = this.searchGroup.controls[ctl];
      if (ctl !== 'project_name_or_number') {
        control.reset();
        control.disable();
      }
    }
  }

  navToProject(project) {
    this.dialogRef.close(project);
  }

  async getProjects() {
    this.fetching = true;
    this.resultSet = [];
    const searchFields: ProjectSearchInput = {
      ...this.searchInput,
    };
    this.resultSet = await this.projQuery.searchProjects(searchFields);
    this.fetching = false;
    this.searchInput = {};
    // this.searchGroup.reset();
  }

  clearFields() {
    this.searchGroup.reset();
  }
}
