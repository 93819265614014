import { Injectable } from '@angular/core';
import { GetAllUsersInput, Project } from '@prime/types';
import { RecentUserProjectsGQL, GetAllUsersGroupedByUserGroupGQL } from '@prime/client/services';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VdcUsersClientQueryService {
  constructor(
    private recentProjs: RecentUserProjectsGQL,
    private usersGrouped: GetAllUsersGroupedByUserGroupGQL
  ) { }

  getUsersRecentlyViewedProjects(user_id: number): Observable<Project[]> {
    return this.recentProjs
      .watch({
        user_id,
      })
      .valueChanges.pipe(map((result) => result.data.recentUserProjects));
  }

  getAllUsersGroupedByUserGroup(input: GetAllUsersInput) {
    return this.usersGrouped
      .fetch({
        input,
      })
      .pipe(map((results) => results.data.getAllUsersGroupedByUserGroup));
  }
}
