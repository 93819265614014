import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VdcUsersClientQueryService } from '@prime/client/services/vdc-users';
import { Project, User } from '@prime/types';
import { AuthService } from 'libs/client/services/client-auth/src/lib/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'prime-user-recent-projects-view',
  templateUrl: './prime-user-recent-projects-view.component.html',
  styleUrls: ['./prime-user-recent-projects-view.component.scss'],
})
export class PrimeUserRecentProjectsViewComponent implements OnInit, OnDestroy {
  projects: Project[] = [];
  projectsSub: Subscription;

  constructor(
    private vdcUserQuery: VdcUsersClientQueryService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.watchCurrentUser().subscribe((user) => {
      if (user) {
        this.watchUserProjects(user);
      }
    });
  }

  watchUserProjects(user) {
    this.projectsSub = this.vdcUserQuery
      .getUsersRecentlyViewedProjects(user.user_id)
      .subscribe((projects) => {
        this.projects = projects;
      });
  }

  ngOnDestroy() {
    if(this.projectsSub){
      this.projectsSub.unsubscribe();
    }
  }

  goToProject(proj_id: number) {
    this.router.navigate(['project', proj_id], { relativeTo: this.route });
  }
}
