import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { VdcDialogService } from '@prime/client/services/dialogs';
import { AuthService } from '@prime/client/services/client-auth';

@NgModule({
  imports: [ApolloModule, HttpLinkModule],
})
export class ApolloGraphQLModule {
  /**
   * This module should only be imported once, in the root module of the application.
   * Do not want separate service instances across the application.
   */
  constructor(
    @Optional() @SkipSelf() module: ApolloGraphQLModule,
    private apollo: Apollo,
    private httpLink: HttpLink,
    private dialogs: VdcDialogService,
    private auth: AuthService
  ) {
    if (module) {
      throw new Error(
        'Attempting to load GraphQLModule in more than one module. Only import in app root module.'
      );
    }

    this.setupApolloConfig();
  }

  setupApolloConfig() {
    const errorLink = onError(({ operation, response, graphQLErrors, networkError }) => {
      let errored = false;
      if (graphQLErrors) {
        errored = true;
        console.error('[GraphQL Errors]:');
        // Our error messages should be in the first item
        const errMsg = graphQLErrors[0].message;
        // // If performing action from an open dialog, we first need to close the dialog then display error messages
        // const openDialogs = this.dialogs.openDialogs;
        // if (openDialogs.length) {
        //   const dialogToClose = openDialogs[openDialogs.length - 1];
        //   dialogToClose?.close();
        // }
        this.dialogs.showError(errMsg, true);
        this.dialogs.snackBarMessage('Error', 'Close', 5000);
        graphQLErrors.forEach((error) => console.error(error));
      }

      if (networkError) {
        console.error(`[Network error]:`);
        console.error(networkError);
      }

      if (errored) {
        console.log('Error with operation:');
        console.log(operation);
      }

      if (response && errored) {
        /**
         * Setting this to undefined allows the request/response cycle to still complete after an error.
         * Components should account for (null checks, etc.) any lack of the data they were requesting as needed then.
         */
        response.errors = undefined;
      }
    });

    const http = this.httpLink.create({
      uri: environment.GRAPHQL_URI,
      headers: new HttpHeaders().set('prime-access-token', this.auth.getLocalAccessToken()),
    });

    this.apollo.create({
      link: errorLink.concat(http),
      cache: new InMemoryCache({
        dataIdFromObject: (type: any) => {
          switch (type.__typename) {
            case 'Project':
              return 'Project_' + type.proj_id;
            case 'Project':
              return 'User_' + type.user_id;
            default:
              return type.id || type._id;
          }
        },
      }),
    });
  }
}
