import { Injectable } from '@angular/core';
import {
  Exact,
  GetProjectActivityHistoryGQL,
  GetProjectInformationViewDocument,
  GetProjectInformationViewQuery,
  GetProjectInformationViewQueryVariables,
  GetProjectNotificationsGQL,
  ProjectSearchGQL,
} from '@prime/client/services';
import { ProjectSearchInput } from '@prime/types';
import { Apollo } from 'apollo-angular';
import { QueryOptionsAlone } from 'apollo-angular/types';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProjectBaseQueryService {
  loading = false;
  constructor(
    private notifs: GetProjectNotificationsGQL,
    private apollo: Apollo,
    private searchGQL: ProjectSearchGQL,
    private activities: GetProjectActivityHistoryGQL
  ) {}

  getProjectNotifications(proj_id: number) {
    return this.notifs
      .fetch({
        proj_id,
      })
      .pipe(map((results) => results.data.project.notifications));
  }

  getProjectManagementData(proj_id: number) {
    return this.apollo
      .watchQuery<GetProjectInformationViewQuery, GetProjectInformationViewQueryVariables>({
        query: GetProjectInformationViewDocument,
        variables: {
          proj_id,
        },
      })
      .valueChanges.pipe(map((result) => result.data));
  }

  searchProjects(searchFields: ProjectSearchInput) {
    return this.searchGQL
      .fetch(
        {
          searchFields,
        },
        {
          fetchPolicy: 'network-only',
        }
      )
      .pipe(map((result) => result.data.projectSearch))
      .toPromise();
  }

  /**
   * Gets the current activity history list for a project.
   * @param proj_id
   */
  getProjectActivityHistory(
    proj_id: number,
    options?: QueryOptionsAlone<Exact<{ proj_id: number }>>
  ) {
    return this.activities
      .fetch({ proj_id }, options)
      .pipe(map((result) => result.data.project.activity_history))
      .toPromise();
  }
}
