<div>
  <h3 class="bold">
    <span class="recent-proj-header">Recently Viewed</span>
  </h3>
  <hr />
</div>

<div *ngIf="recentProjects?.length === 0">
  <h3>
    <span class="recent-proj-header">No recent projects</span>
  </h3>
  <hr />
</div>

<div class="recent-container">
  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-4" *ngFor="let project of recentProjects">
      <prime-project-card [project]="project" (click)="onProjectSelected(project.proj_id)" class="proj-card">
        <div class="favorite" (click)="setFavorite(project); $event.stopPropagation()">
          <mat-icon> {{project.is_favorite ? 'star': 'star_border'}}</mat-icon>
        </div>
        <div class="row">
          <div class="col-md-12">
            <span>Revit Version:
              <span class="bold">{{ project.software_tracking?.rvtVersion }}</span>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <span>Project Number:
              <span class="bold">{{ project.proj_num }}</span>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div>
              VDC Coordinator:
              <span class="bold">{{ project.coordinator?.full_name }}</span>
            </div>
          </div>
        </div>

        <div *ngIf="projectHasValidChangeOrders(project)" class="row">
          <div class="col-md-12">
            <div>
              Change Orders:
              <span *ngIf="getOpenChangeOrders(project) > 0" class="bold"> OPEN: {{getOpenChangeOrders(project)}}</span>
              <span *ngIf="getClosedChangeOrders(project) > 0" class="bold"> CLOSED: {{getClosedChangeOrders(project)}}</span>
              <span *ngIf="getInReviewChangeOrders(project) > 0" class="bold"> IN REVIEW: {{getInReviewChangeOrders(project)}}</span>
            </div>
          </div>
        </div>

      </prime-project-card>
    </div>
  </div>
</div>