import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UserMenuComponent } from "./user-menu/user-menu.component";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  imports: [CommonModule, MatMenuModule, MatButtonModule],
  declarations: [UserMenuComponent],
  exports: [UserMenuComponent],
})
export class ClientUiUserMenuModule {}
