import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProjectSearchResult } from '@prime/client/services';

@Component({
  selector: 'prime-project-search-list',
  templateUrl: './project-search-list.component.html',
  styleUrls: ['./project-search-list.component.scss'],
})
export class ProjectSearchListComponent {
  @Output() projectSelected = new EventEmitter<ProjectSearchResult>();
  @Input() resultSet: ProjectSearchResult[] = [];
  @Input() modal: boolean;
  displayedColumns = ['proj_num', 'proj_name', 'status', 'region'];

  constructor(private modalController: ModalController) {}

  navToProject(project: ProjectSearchResult) {
    // Check if contained in a modal view
    if (!this.modal) {
      this.projectSelected.next(project);
    } else {
      this.modalController.dismiss(project);
    }
  }
}
