import { Injectable } from '@angular/core';
import { ClientEnvironment } from './client-env.interface';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ClientEnvironmentService {
  private _env: ClientEnvironment = environment;
  get env(): ClientEnvironment {
    return {
      ...this._env,
    };
  }

  constructor() {}
}
