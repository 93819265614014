<div class="form-group">
  <div class="input-group">
    <input
      type="text"
      class="form-control"
      [formControl]="searchInputControl"
      placeholder="Search Project Name or #"
      (keydown.enter)="search()"
    />

    <prime-loading-button
      [buttonText]="'Submit'"
      (buttonClicked)="search()"
      [loading]="fetching"
    ></prime-loading-button>

    <prime-loading-button
      [buttonText]="'Advanced Search'"
      (buttonClicked)="showAdvancedSearchDialog()"
      [loading]="loadingAdvanced"
    ></prime-loading-button>
  </div>
</div>
